import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {

  constructor(private router: Router) { }

  BaseUrl() {
     return "https://dev-portal.avisoconnex.com.au"
  }
  RedirectUrl() {
    return "https://dev.avisoconnex.com.au"
  }
}
